import React from "react"

import Layout from "../components/layout"
import RSVP from "../components/RSVP"
import SEO from "../components/seo"

const RsvpPage = () => (
  <Layout>
    <SEO title="RSVP" />
    <RSVP />
  </Layout>
)

export default RsvpPage
