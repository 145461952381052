import React from "react"
import { navigate } from "gatsby"
import "./form.css"

const guestlist = require("../assets/guestlist.json")
const guestNames = Object.keys(guestlist)

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class RSVP extends React.Component {
  state = {
    //guest info
    guestName: "",
    guestNameCorrection: "",
    plusOne: "",
    email: "",
    dietaryRestrictions: "",
    dietaryRestrictions2: "",
    attending: null,

    // form state
    formError: false,
    hasPlusOne: false,
    submitting: false,
    submitMessage: "submit",
    submitError: "",
    override: false,

    // suggestion state
    suggestions: guestNames,
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
  }

  guestNameOnChange = e => {
    const suggestions = this.state.suggestions
    const guestName = e.currentTarget.value

    let filteredSuggestions = []
    let formError = false

    if (guestName.length >= 3) {
      filteredSuggestions = suggestions.filter(suggestion =>
        suggestion.toLowerCase().startsWith(guestName.toLowerCase().slice(0))
      )
      formError =
        filteredSuggestions.length === 0 &&
        (!guestlist[this.state.guestNameCorrection] ||
          !guestlist[this.state.guestName])
    }

    const casedName = guestNames.filter(name => {
      return name.toLowerCase() === guestName.toLowerCase()
    })

    // console.log("casedName?", casedName)

    const stateName = casedName[0] ? casedName[0] : guestName
    // console.log("stateName", stateName)

    const correctionOrInsensitiveInput = this.state.guestNameCorrection
      ? guestlist[this.state.guestNameCorrection]
      : stateName

    const finalInput = correctionOrInsensitiveInput
      ? correctionOrInsensitiveInput
      : stateName

    // console.log("finalInput", finalInput)
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: guestlist[finalInput] ? false : true,
      guestName: finalInput,
      hasPlusOne:
        guestlist[finalInput] && guestlist[finalInput].date === "pair"
          ? true
          : false,
      formError: formError,
    })
  }

  guestNameOnClick = suggestion => {
    const correctionOrSuggestion = this.state.guestNameCorrection
      ? guestlist[this.state.guestNameCorrection]
      : guestlist[suggestion]
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      guestName: suggestion,
      hasPlusOne:
        correctionOrSuggestion && correctionOrSuggestion.date === "pair"
          ? true
          : false,
    })
  }

  guestNameOnKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        guestName: filteredSuggestions[activeSuggestion],
        hasPlusOne:
          guestlist[filteredSuggestions[activeSuggestion]] &&
          guestlist[filteredSuggestions[activeSuggestion]].date === "pair"
            ? true
            : false,
      })
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 })
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 })
    }
  }

  handleInputChange = e => {
    const { name, value } = e.target
    if (name === "guestName") {
      this.guestNameOnChange(e)
    }
    if (name === "guestNameCorrection") {
      const casedName = guestNames.filter(name => {
        return name.toLowerCase() === value.toLowerCase()
      })
      if (casedName[0]) {
        this.setState({
          hasPlusOne: guestlist[casedName[0]].date === "pair" ? true : false,
          guestNameCorrection: casedName,
        })
      } else {
        this.setState({
          guestNameCorrection: value,
        })
      }
    } else {
      this.setState({
        [name]: value,
      })
    }
  }

  handleSubmit = event => {
    const guestName = this.state.guestName
    const plusOne = this.state.plusOne
    const email = this.state.email
    const dietaryRestrictions = this.state.hasPlusOne
      ? `1: ${this.state.dietaryRestrictions} 2: ${this.state.dietaryRestrictions2}`
      : this.state.dietaryRestrictions
    const attending = this.state.attending

    this.setState({ submitting: true, submitMessage: "submitting" })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "rsvp",
        guestName,
        plusOne,
        email,
        dietaryRestrictions,
        attending,
      }),
    })
      .then(() => {
        this.setState({ submitting: false, submitMessage: "submitted" })
        if (this.state.attending === "true") {
          navigate("/attending")
        } else if (this.state.attending === "false") {
          navigate("/not-attending")
        } else {
          this.setState({
            submitting: false,
            submitMessage: "sorry that didn't work, did you forget something?",
          })
        }
      })
      .catch(error =>
        this.setState({
          submitting: false,
          submitMessage: "try again?",
          submitError: error,
        })
      )
    event.preventDefault()
  }

  render() {
    const {
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      guestName,
      guestNameCorrection,
    } = this.state

    const requireDietaryRestrictions =
      this.state.attending === "true" ? true : false
    let suggestionsListComponent
    let twoSuggestions = filteredSuggestions.length === 2
    let oneSuggestion = filteredSuggestions.length === 1
    let extraStyle
    let checklist

    if (true) {
      checklist = (
        <div className="checklist">
          <ol
            style={{
              display:
                !this.state.guestName ||
                !this.state.email ||
                this.state.attending === null ||
                (!this.state.dietaryRestrictions && requireDietaryRestrictions)
                  ? null
                  : "none",
            }}
          >
            <li>
              <p>
                {this.state.guestName ? "✅" : "🚫"} <span> nom</span>
              </p>
            </li>
            <li>
              <p>
                {this.state.email ? "✅" : "🚫"} <span> email</span>
              </p>
            </li>
            <li>
              <p>
                {this.state.attending !== null ? "✅" : "🚫"} <span> y?n</span>
              </p>
            </li>
            <li style={{ display: requireDietaryRestrictions ? null : "none" }}>
              <p>
                {this.state.dietaryRestrictions ? "✅" : "🚫"}{" "}
                <span> dîner</span>
              </p>
            </li>
          </ol>
          <div
            style={{
              marginBottom: "-20px",
              display:
                !this.state.guestName ||
                !this.state.email ||
                this.state.attending === null ||
                (!this.state.dietaryRestrictions && requireDietaryRestrictions)
                  ? "none"
                  : "block",
            }}
          >
            <p
              style={{
                marginTop: "20px",
                fontSize: "20px",
              }}
            >
              🚀
            </p>
          </div>
        </div>
      )
    }

    if (oneSuggestion || twoSuggestions) {
      extraStyle = oneSuggestion ? "-7px" : "-17px"
    }

    if (showSuggestions && guestName && !guestlist[guestNameCorrection]) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul
            className="suggestions"
            style={{ bottom: extraStyle ? extraStyle : null }}
          >
            {filteredSuggestions.map((suggestion, index) => {
              let className
              let name
              let display = false

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active"
                name = <span>&rarr; Hi {suggestion}!</span>
                display = true
              } else {
                name = <span>{suggestion}</span>
              }

              // if active suggestion is w/in one of the active suggestion display it
              if (
                index === activeSuggestion - 1 ||
                index === activeSuggestion + 1
              ) {
                display = true
              }

              //if first or last are selected show additional option above/below
              if (activeSuggestion === 0 && index === activeSuggestion + 2) {
                display = true
              }
              if (
                activeSuggestion === filteredSuggestions.length - 1 &&
                index === activeSuggestion - 2
              ) {
                display = true
              }

              return (
                <li
                  className={className}
                  id={index}
                  key={suggestion}
                  onClick={() => this.guestNameOnClick(suggestion)}
                  style={{ display: display ? "block" : "none" }}
                >
                  {name}
                </li>
              )
            })}
          </ul>
        )
      } else if (
        filteredSuggestions.length === 0 &&
        !guestlist[this.state.guestNameCorrection]
      ) {
        suggestionsListComponent = (
          <div className="no-suggestions">
            {!this.state.override ? (
              <span className="errorText">
                <em>Did we get it wrong?</em>
                <ul className="radioPair radioPair__small">
                  <li
                    onClick={() =>
                      this.setState({ override: true, formError: false })
                    }
                  >
                    <label className="radioLabel">
                      <input type="radio" />Y
                    </label>
                  </li>
                  <span> / </span>
                  <li onClick={() => this.setState({ guestName: "" })}>
                    <label className="radioLabel">
                      <input type="radio" />N
                    </label>
                  </li>
                </ul>
              </span>
            ) : null}
            {this.state.override ? (
              <span className="errorText">
                <input
                  style={{
                    position: "relative",
                    fontSize: "12px",
                    width: "70%",
                    bottom: "-30px",
                    left: "-15px",
                  }}
                  type="text"
                  name="guestNameCorrection"
                  id="guestNameCorrection"
                  // autoComplete="off"
                  placeholder="name as printed on invitation"
                  value={this.state.guestNameCorrection}
                  onChange={this.handleInputChange}
                />
              </span>
            ) : null}
          </div>
        )
      }
    }

    if (guestlist[this.state.guestNameCorrection]) {
      suggestionsListComponent = (
        <div className="errorText">
          <span
            style={{
              marginTop: "-75px",
              fontSize: "14px",
              position: "absolute",
              marginLeft: "100px",
            }}
          >
            Noted!
          </span>
        </div>
      )
    }

    return (
      <form
        name="rsvp"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        // autoComplete="off"
        action={
          this.state.attending === "true" ? "/attending" : "/not-attending"
        }
        onSubmit={this.handleSubmit}
      >
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="bottom-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <div
                      className="oval-card-container"
                      style={{ height: "100%" }}
                    >
                      <div className="oval-content">
                        <h4>Répondez s'il vous plaît</h4>
                      </div>
                      <div
                        className="oval-card"
                        style={{
                          height: "105%",
                          width: "105%",
                          margin: "-30px -5px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="rsvp" />
                  <h3
                    className="guestsTitle"
                    style={{ transform: "rotate(-14deg)" }}
                  >
                    Guest(s)
                  </h3>
                  <div className="namegroup">
                    <input
                      type="text"
                      name="guestName"
                      id="guestName"
                      // autoComplete="off"
                      placeholder="your name"
                      value={this.state.guestName}
                      onChange={this.handleInputChange}
                      onKeyDown={this.guestNameOnKeyDown}
                      required
                      style={{ bottom: "-10px" }}
                    />
                    {this.state.guestName && this.state.guestName.length >= 3
                      ? suggestionsListComponent
                      : null}
                    <input
                      type="text"
                      name="plusOne"
                      id="plusOne"
                      placeholder="your date"
                      value={this.state.plusOne}
                      onChange={this.handleInputChange}
                      style={{
                        display: this.state.hasPlusOne ? null : "none",
                        bottom: "-15px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="bottom-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <h3 style={{ transform: "rotate(-21deg)" }}>Email</h3>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="you@cyberspace.web"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h3 style={{ transform: "rotate(-11deg)" }}>Attending</h3>
                  <ul className="radioPair" onClick={this.handleInputChange}>
                    <li
                      style={
                        this.state.attending === "true"
                          ? { color: "green", borderColor: "green" }
                          : null
                      }
                      className={
                        this.state.attending === "true" ? "active yes" : "yes"
                      }
                    >
                      <label className="radioLabel">
                        <input
                          type="radio"
                          name="attending"
                          value={"true"}
                          required
                        />
                        Oui
                      </label>
                    </li>
                    <li
                      style={
                        this.state.attending === "false"
                          ? { color: "red", borderColor: "red" }
                          : null
                      }
                      className={
                        this.state.attending === "false" ? "active" : null
                      }
                    >
                      <label className="radioLabel">
                        <input
                          type="radio"
                          name="attending"
                          value={"false"}
                          required
                        />
                        Non
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="bottom-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div
                    className="column-cell"
                    style={{
                      visibility: !this.state.attending ? "visible" : "hidden",
                      position: !this.state.attending ? null : "absolute",
                    }}
                  >
                    <h3 style={{ transform: "rotate(-11deg)", bottom: "60px" }}>
                      3... 2... 1...
                    </h3>
                    <div style={{ marginTop: "60px" }}>{checklist}</div>
                  </div>
                  <div
                    className="column-cell"
                    style={{
                      visibility:
                        this.state.attending === "true" ? "visible" : "hidden",
                      position:
                        this.state.attending === "true" ? null : "absolute",
                    }}
                  >
                    <h3 style={{ transform: "rotate(-7deg)" }}>
                      Dietary Restrictions
                    </h3>
                    <div
                      style={
                        this.state.hasPlusOne
                          ? {
                              width: "50%",
                              display: "inline-block",
                              paddingLeft: "8vw",
                              marginTop: "20px",
                            }
                          : { marginLeft: "40px" }
                      }
                      className="dietary-restrictions"
                    >
                      <label
                        htmlFor="dietaryRestrictions"
                        className="not-radio"
                        name="dietaryRestrictions"
                        id="dietaryRestrictions"
                        style={{
                          display: this.state.hasPlusOne ? "inline" : "none",
                          marginLeft: "8px",
                        }}
                      >
                        {/* needed so form populates correctly */}
                        <span style={{ display: "none" }}>
                          dietaryRestrictions
                        </span>
                        {this.state.hasPlusOne ? "you:" : ""}
                      </label>
                      <select
                        name="dietaryRestrictions"
                        id="dietaryRestrictions"
                        onChange={this.handleInputChange}
                      >
                        <option value="" disabled selected>
                          Select one
                        </option>
                        <option value="None">None</option>
                        <option value="Vegetarian">Vegetarian</option>
                        <option value="Vegan">Vegan</option>
                        <option value="Gluten Free">Gluten Free</option>
                        <option value="Kosher">Kosher</option>
                        <option value="Food Allergies">Food Allergies</option>
                      </select>
                    </div>
                    {this.state.hasPlusOne ? (
                      <div style={{ width: "50%", display: "inline-block" }}>
                        <label
                          className="not-radio"
                          htmlFor="dietaryRestrictions2"
                          style={{ marginLeft: "3px" }}
                        >
                          your date:
                        </label>
                        <select
                          name="dietaryRestrictions2"
                          id="dietaryRestrictions2"
                          onChange={this.handleInputChange}
                        >
                          <option value="" disabled selected>
                            Select one
                          </option>
                          <option value="None">None</option>
                          <option value="Vegetarian">Vegetarian</option>
                          <option value="Vegan">Vegan</option>
                          <option value="Gluten Free">Gluten Free</option>
                          <option value="Kosher">Kosher</option>
                          <option value="Food Allergies">Food Allergies</option>
                        </select>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="column-cell"
                    style={{
                      visibility:
                        this.state.attending === "false" ? "visible" : "hidden",
                      position:
                        this.state.attending === "false" ? null : "absolute",
                    }}
                  >
                    <h3 className="no-thankyou-header">
                      thank you
                      <br />
                      <span className="no-thankyou">&</span>
                      <br />
                      miss you!
                    </h3>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <input
                    style={{ marginTop: "-8px" }}
                    disabled={
                      !this.state.guestName ||
                      !this.state.email ||
                      this.state.attending === null ||
                      (!this.state.dietaryRestrictions &&
                        requireDietaryRestrictions)
                      // this.state.formError
                      // ? true
                      // : undefined
                    }
                    type="submit"
                    className="button-link__roundstyle"
                    value={this.state.submitMessage}
                  />
                  {this.state.attending ? checklist : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
